<template>
  <div class="head_box">
    <div class="head_img">
      <div class="head_logo"></div>
      <div class="head_text"> 山东英大招投标集团</div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {

    };
  },
  methods: {

  },
};
</script>
<style scoped>
@import url("./imgHead.css");
</style>