<template>
  <div class="content_yd">
    <div class="content_wrap_yd">
      <div class="content_crumbs_yd">
        <el-breadcrumb separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class=" content_content_yd" v-for="item in NewsData.value" :key="item.id">
        <div class="content_content_title_yd">
          {{ item.title }}
        </div>

        <div class="content_content_crumbs_yd">
          <div class="content_content_crumbs_time_yd">
            <img src="./../pic/timer.png" alt="">
            发布时间：{{item.updateTime}}
          </div>
        </div>

        <div class="content_content_item_wrap_yd">
          <div class="content_content_item_yd">
            <p v-html="item.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mainStore } from "@/store";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
export default {
  filters: {
    ellipsis(value) {
      value = value.replace(/<\/?.+?>/g, "");
      value = value.replace(/ /g, " ");
      value = value.replace(/.*?\}/g, " ");
      value = value.replace(/.*?\}/g, "");
      return value;
    },
  },
  setup() {
    const router = useRouter();
    const store = mainStore();
    const index = ref()
    const NewsData = ref([])
    const arrT = () => {
      if (index.value == 1) {
        NewsData.value = storeToRefs(store).YdEngineering;
        console.log(NewsData.value.value, 'NewsData1');
      } else if (index.value == 4) {
        NewsData.value = storeToRefs(store).YdManagement;
        console.log(NewsData, 'NewsData4');
      }
    }
    onMounted(async () => {
      index.value = router.currentRoute.value.query.index;
      await arrT()
    });
    return {
      NewsData,
      arrT,
      index
    };
  },
};

</script>

<style scoped>
@import url("./conMes.css");
</style>

