<template>
  <div class="footer_wrap">
    <div class="footer_content">
      <div class="center">
        <div class="center_l">
          <div class="center_l_box">
            <img src="./pic/footerLogo.png" alt="" class="center_l_box_img">
          </div>
          <div class="center_l_box_text" style="font-family: 'Prioritize';">儒风商才 义利合一</div>
        </div>
        <div v-html="showHtml(webData.footer)" class="fwb"></div>
        <div class="fgx"></div>
        <div class="center_r">
          <div style="width: 5rem;height:5rem;">
            <img src="./pic/code1.png" alt="" class="center_l_box_img">
          </div>
          <div class="center_l_box_text">齐鲁观水公众号</div>
        </div>
        <div class="center_r">
          <div style="width:5rem;height:5rem;">
            <img src="./pic/code2.png" alt="" class="center_l_box_img">
          </div>
          <div class="center_l_box_text">拍卖公众号</div>
        </div>
        <div class="center_r">
          <div style="display: flex;">
            <span>
              <img src="./pic/message.png" alt="">
            </span>
            <span style="font-size: 18px;font-weight: 500;color: rgba(255, 255, 255, 1);margin-left:10px;">
              在线留言
            </span>
          </div>
          <div style="display: flex;">
            <span>
              <img src="./pic/wx.png" alt="">
            </span>
            <span style="font-size: 18px;font-weight: 500;color: rgba(255, 255, 255, 1);margin-left:10px;">
              官方微信
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_all">
      <div>齐鲁观水信息技术有限公司版权所有</div>
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank" style="color: rgba(51, 51, 51, 1);text-decoration: none;">
          <span>鲁ICP备10025418号-4</span>
        </a>
        <!-- <span>鲁ICP备17027605号-1</span><span style="margin-left:80px">鲁公网安备鲁公网安备37010202001156号</span> -->
      </div>
    </div>
  </div>
</template>


<script>
import imgData from "@/data.js";
import { reactive, computed } from "vue";
import { submitUserInfo } from "@/api/index";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { ElMessage } from "element-plus";
export default {
  props: {
    webTitle: String,
  },
  setup() {
    const store = mainStore();
    const webData = computed(() => storeToRefs(store).webData)
    const showHtml = (str) => {
      return str
        ? str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#39;/g, "'")
          // .replace(/.*?\}/g, "")
          .replace(/&amp;nbsp;/g, "\u3000")
        : "";
    };
    const footerList = [
      {
        title: "注册声明",
      },
      {
        title: "版权声明",
      },
      {
        title: "售后服务",
      },
      {
        title: "隐私声明",
      },
    ];
    const reLoadImg = store.setactiveImgCodeInfo;
    const postUserInfo = () => {
      if (!data.formData.code) {
        ElMessage({
          type: "error",
          message: "请输入验证码",
        });
        return;
      }
      if (
        storeToRefs(store).activeImgCode.value.toLowerCase() ==
        data.formData.code.toLowerCase()
      ) {
        submitUserInfo(data.formData)
          .then(() => {
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            clearFormData();
            reLoadImg();
          })
          .catch(() => {
            ElMessage({
              type: "error",
              message: "提交失败，请检查格式是否正确",
            });
          });
      } else {
        ElMessage({
          type: "error",
          message: "请输入正确的验证码",
        });
      }
    };

    const codeImg = computed(() => {
      return storeToRefs(store).activeImg.value;
    });
    const codeKey = computed(() => {
      return storeToRefs(store).activeImgKey.value;
    });
    const clearFormData = () => {
      data.formData.userName = "";
      data.formData.userPhone = "";
      data.formData.content = "";
      data.formData.code = "";
    };
    const toHref = () => {
      window.open("https://beian.miit.gov.cn/", "_blank");
    };
    const data = reactive({
      formData: {},
      footerList,
      postUserInfo,
      clearFormData,
      toHref,
      webData,
      showHtml,
    });

    return {
      ...data,
      ...imgData,
      codeImg,
      codeKey,
      reLoadImg,
    };
  },
};
</script>

<style >
@import url("./Footer.css");
</style>